// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

const RailsUjs = require("@rails/ujs")
RailsUjs.confirm = require('./utils/confirm.js').default; 
// RailsUjs.showDialog = require('./utils/confirm.js').default;
RailsUjs.start();

require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")

var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require('bootstrap');
require('admin-lte');
require('select2');
$.fn.select2.amd.define('select2/i18n/pt-BR',[],require("select2/src/js/select2/i18n/pt-BR"));
require("chartkick")
require("chart.js")

import './stylesheet.js';

var cocoon = require('./cocoon.js');
cocoon.default(jQuery);

var utils = require('./utils.js');
utils.default(jQuery);

window.poloni = { utils: {} }
window.poloni.utils.checkboxes = require('./utils/checkboxes.js').default;
window.poloni.utils.select2_ajax = require('./utils/select2_ajax.js').default;
window.poloni.utils.validation_ajax = require('./utils/validation_ajax.js').default;
window.poloni.utils.mask = require('./utils/mask.js').default;
window.poloni.utils.to_float = function(val_s){
  var val = 0;

  try {
    val = parseFloat(val_s.replace('.','').replace(',','.'))
    if (isNaN(val)){ return 0 }
    return val
  } catch (e) {
    return 0
  }

}
window.poloni.charges = require('./charges.js').default;
window.poloni.services = require('./services.js').default;
window.poloni.nfse = require('./nfse.js').default;
window.poloni.boleto = require('./boleto.js').default;
window.poloni.accomplished_service = require('./accomplished_service.js').default;
window.poloni.counters = require('./utils/counters.js').default;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%#= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("@rails/actiontext")
